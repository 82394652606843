<template>
  <error-layout :code="403" :error="$t('views.errors.accessDenied')"></error-layout>
</template>

<script>
import ErrorLayout from '../layouts/ErrorLayout.vue';

export default {
  metaInfo() {
    return {
      title: this.$t('views.errors.accessDenied'),
    };
  },
  components: { ErrorLayout },
};
</script>
